import axios from "axios";
import authHeader from "./auth-header";

import { URL } from "../models/constants";

const API_URL = URL + "admin/";

class UserService {
  allprojects() {
    return axios.get(API_URL + "allprojects", { headers: authHeader() });
  }

  allusers() {
    return axios.get(API_URL + "allusers", { headers: authHeader() });
  }

  allteams() {
    return axios.get(API_URL + "allteams", { headers: authHeader() });
  }

  validateTasks() {
    return axios.get(API_URL + "validateTasks", { headers: authHeader() });
  }

  openL1Tasks() {
    return axios.get(API_URL + "openL1Tasks", { headers: authHeader() });
  }

  openL2Tasks() {
    return axios.get(API_URL + "openL2Tasks", { headers: authHeader() });
  }

  openL3Tasks() {
    return axios.get(API_URL + "openL3Tasks", { headers: authHeader() });
  }

  finishRecords() {
    return axios.get(API_URL + "finishRecords", { headers: authHeader() });
  }

  removeTaken() {
    return axios.get(API_URL + "removeTaken", { headers: authHeader() });
  }

  getTasksDownload() {
    return axios.get(API_URL + "loadTaskDownload", { headers: authHeader() });
  }

  generateTaskDownload(data) {
    return axios.get(URL + "downloadtask/" + data[0] + "/" + data[1], {
      headers: authHeader(),
    });
  }

  generateQCTaskDownload(data) {
    return axios.get(URL + "downloadqctask/" + data, {
      headers: authHeader(),
    });
  }

  getTodaysCounts() {
    return axios.get(API_URL + "getTodaysCounts", { headers: authHeader() });
  }

  getUploadDetails(data) {
    return axios.get(URL + "overview/getUploadDetails/" + data, { headers: authHeader() });
  }

  getActiveUploadDetailsFP() {
    return axios.get(URL + "overview/getActiveUploadDetailsFP", { headers: authHeader() });
  }

  getActiveUploadDetailsCH() {
    return axios.get(URL + "overview/getActiveUploadDetailsCH", { headers: authHeader() });
  }

  getloadModifyUpload(data) {
    return axios.get(URL + "overview/loadModifyUpload/"+ data, { headers: authHeader() });
  }

  getdeativateUpload(data) {
    return axios.get(URL + "overview/deativateUpload/"+data, { headers: authHeader() });
  }

  getupdateSLADate(data) {
    return axios.post(URL + "overview/updateSLADate/"+data[0],data[1], { headers: authHeader() });
  }

  getupdateInstructions(data) {
    return axios.post(URL + "overview/updateinstructions/"+data[0],data[1], { headers: authHeader() });
  }

  getActiveTasksUploads() {
    return axios.get(URL + "overview/open_tasks", { headers: authHeader() });
  }



}

export default new UserService();
