<template>
    <div class="container">
      <div class="card" style="width: 100%">
            <b-tabs content-class="mt-3">
                <b-tab title="General Summary" active>
                    <div>
                       
                      <b-container class="bv-example-row">
                          <b-row>
                            <b-col><h5 class="card-title">Upload: <b>{{ key_data.Name }} </b></h5></b-col>
                            <b-col>
                              <b-button v-if="key_data.UploadStatus == 'Finished'" variant="success" style="float: right;">{{ key_data.UploadStatus}}</b-button>
                              <b-button v-else-if="key_data.UploadStatus == 'Not Started'" variant="danger" style="float: right;">{{ key_data.UploadStatus}}</b-button>
                              <b-button v-else-if="key_data.UploadStatus == 'In Production'" variant="secondary" style="float: right;">{{ key_data.UploadStatus}}</b-button>
                              <b-button v-else-if="key_data.UploadStatus == 'In QC2'" variant="dark" style="float: right;">{{ key_data.UploadStatus}}</b-button>
                              <b-button v-else variant="warning" style="float: right;">{{ key_data.UploadStatus}}</b-button>
                            </b-col>
                          </b-row>
                        </b-container> 

                        
                        <b-container class="bv-example-row">
                          <b-row>
                            <b-col><p>Project Name: <b>{{ key_data.PrName }}</b><br>
                              Billable: <b>
                              <font-awesome-icon icon="check" v-if="key_data.Billable" />
                              <font-awesome-icon icon="times-circle" v-else /></b>
                              <br>
                              Total Records: <b>{{ key_data.total }}</b>
                              <br>

                              <span v-if="key_data.tot_sets>0">Total sets: <b>{{key_data.tot_sets }}</b> </span>

                              <br><br>




<!--                               <b-form-group label="Individual radios" v-slot="{ ariaDescribedby }">
                                <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="IST">India</b-form-radio>
                                <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="UTC">UTC</b-form-radio>
                                <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="BST">London</b-form-radio>
                                <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="MTC">Phoenix</b-form-radio>
                              </b-form-group> -->


                              <b><u>Key Dates (Phonenix (UTC -7)):</u></b><br>
                              Upload Date: <b>{{ [key_data.Dates.Uploaded,selected] | moment }}</b><br>
                              Production SLA date: <b>{{ [key_data.Dates.SLA_date,selected] | moment }}</b><br>
                              Production Start Date: <b>{{ [key_data.Dates.Started,selected] | moment }}</b><br>
                              Production Finish Date: <b>{{ [key_data.Dates.ProductionFinish,selected] | moment }}</b><br>
                              Return To the Client: <b>{{ [key_data.Dates.ReturnToClinet,selected] | moment }}</b></p>
                            </b-col>
                            <b-col>
                              <Bar
                                id="my-chart-id"
                                :options="chartOptions"
                                :data="chartData"
                              />
                            
                            </b-col>
                          </b-row>
                        </b-container> 
                      

                    </div>
                </b-tab>
                <b-tab title="Production Performance">
                  <div>
                       
                       <b-container class="bv-example-row">
                           <b-row>
                             <b-col><h5 class="card-title">Upload: <b>{{ key_data.Name }} </b></h5></b-col>
                             <b-col>
                               <b-button v-if="key_data.UploadStatus == 'Finished'" variant="success" style="float: right;">{{ key_data.UploadStatus}}</b-button>
                               <b-button v-else-if="key_data.UploadStatus == 'Not Started'" variant="danger" style="float: right;">{{ key_data.UploadStatus}}</b-button>
                               <b-button v-else-if="key_data.UploadStatus == 'In Production'" variant="secondary" style="float: right;">{{ key_data.UploadStatus}}</b-button>
                               <b-button v-else-if="key_data.UploadStatus == 'In QC2'" variant="dark" style="float: right;">{{ key_data.UploadStatus}}</b-button>
                               <b-button v-else variant="warning" style="float: right;">{{ key_data.UploadStatus}}</b-button>
                             </b-col>
                           </b-row>
                         </b-container> 
 
                         
                         <b-container class="bv-example-row">
                           <b-row>
                             <b-col><p>
                               Production Hours So Far: <b>{{ key_data.Hours.ProductionHours }}</b><br>
                               Production Hours Projected: <b>{{ key_data.Hours.ProjectedProductionHours }}</b><br>
                               Production Hours Left: <b>{{ key_data.Hours.ProductionHoursLeft }}</b><br>
                               QC Hours So Far: <b>{{ key_data.Hours.QCHours }}</b><br>
                               QC Hours Projected: <b>{{ key_data.Hours.ProjectedQCHours }}</b><br>
                               QC Hours Left: <b>{{ key_data.Hours.QCHoursLeft }}</b>
                               <br><br><br><br><br>
                              </p>
                             </b-col>
                             <b-col>
                               <Bar
                                 id="my-performance-chart-id"
                                 :options="chartOptions"
                                 :data="chartData_performance"
                               />
                             
                             </b-col>
                           </b-row>
                         </b-container> 
                       
 
                     </div>

                </b-tab>
                <b-tab title="Quality">
                  <div>
                       
                       <b-container class="bv-example-row">
                           <b-row>
                             <b-col><h5 class="card-title">Upload: <b>{{ key_data.Name }} </b></h5></b-col>
                             <b-col>
                               <b-button v-if="key_data.UploadStatus == 'Finished'" variant="success" style="float: right;">{{ key_data.UploadStatus}}</b-button>
                               <b-button v-else-if="key_data.UploadStatus == 'Not Started'" variant="danger" style="float: right;">{{ key_data.UploadStatus}}</b-button>
                               <b-button v-else-if="key_data.UploadStatus == 'In Production'" variant="secondary" style="float: right;">{{ key_data.UploadStatus}}</b-button>
                               <b-button v-else-if="key_data.UploadStatus == 'In QC2'" variant="dark" style="float: right;">{{ key_data.UploadStatus}}</b-button>
                               <b-button v-else variant="warning" style="float: right;">{{ key_data.UploadStatus}}</b-button>
                             </b-col>
                           </b-row>
                         </b-container> 
 
                         
                         <b-container class="bv-example-row">
                           <b-row>
                             <b-col><p>
                               QC Tasks completed: <b>{{ key_data.Quality.QCTasksCompleted }}</b><br>
                               QC Tasks pending: <b>{{ key_data.Quality.QCTasksNoComleted }}</b><br>
                              <br>

                              <Bar
                                 id="my-qualty-chart-id"
                                 :options="chartOptions"
                                 :data="chartData_quality"
                               />


                              </p>

                              <br><br>

                              <router-link
                                    :to="'../uploadqcsummary/' + key_data.id"
                                    class="custom-link green"
                                  >
                                    Details
                                  </router-link>



                             </b-col>
                             <b-col>
                             

                              <b>Errors summary: </b><br>

                               <table class="table table-striped table-condensed">
                                <thead>
                                  <tr>
                                    <th>Error Type</th>
                                    <th>Errors Made</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(value, key) in key_data.Quality.errors" :key="key">
                                    <td>{{ key }}</td>
                                    <td>{{ value }}</td>
                                  </tr>
                                </tbody>
                              </table>





                             </b-col>
                           </b-row>
                         </b-container> 
                       
 
                     </div>

                </b-tab>
                <b-tab title="Download Links"><div>
                  <p>
                    Overall File Download:
                    <button
                        class="custom-link green margin-left"
                        type="button"
                        @click="generateCsv(key_data.id)"
                      >
                        Generate Output File
                      </button>    
                    </p>
                    <p>
                    Tasks outpus:
                    <button v-for="projecttask in key_data.Tasksdownload" :key="projecttask.name"
                        class="custom-link green margin-left"
                        type="button"
                        @click="generateTaskCsv([key_data.id, projecttask.id])"
                      >
                        {{projecttask.Name}}
                      </button> 
                    </p>
                    <p>
                    QC Module File Download:
                    <button
                        class="custom-link green margin-left"
                        type="button"
                        @click="generateQCTaskCsv(key_data.id)"
                      >
                        Generate Output File
                      </button>    
                    </p>
                    <p>
                      <download-csv
                        v-if="json_data.length > 0"
                        class="custom-link"
                        :data="json_data"
                      >
                        Download Data
                      </download-csv>
                    </p>


                </div></b-tab>
            </b-tabs>
        </div>
    </div>
  </template>
  
  <script>
  import AdminService from "../services/admin.service";
  import ProjectService from "../services/project.service";

  var moment = require('moment-timezone');

  import { Bar } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from 'chart.js'

  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
  
  export default {
    name: "UploadOverview",

    components: { Bar },

    data: () => ({

      selected: 'MTC',


    json_data: [],
    key_data: {},
    loaded: false,
    chartData: null,
    chartOptions: {
    scales: {
      y: {
        beginAtZero: true
      }
    }
  },
    chartData_performance: {
                  'labels': ['Production','QC'],
                  'datasets': [
                    {
                      'label': 'Time (min) per record',
                      'backgroundColor': '#41b883',
                      'data': []
                    }
                  ]
                },
    chartData_quality: null
      }),
      async mounted () {
        this.loaded = false

        try {
          const { data } = await AdminService.getUploadDetails(this.$route.params.id)

          this.key_data = data;
          this.chartData =  data.Records
          this.chartData_performance.datasets[0].data = [data.Hours.MinutesPerRecord.Production,data.Hours.MinutesPerRecord.QC]
          this.chartData_quality = data.Quality.chart

          this.loaded = true
        } catch (e) {
          console.error(e)
        }
      },
  
  
    created() {
      document.title = "DOCUMENT REVIEW || INDIVILLAGE VALIDATION PLATFORM";
    },
  
    methods: {
      generateCsv(data) {
        ProjectService.generateDownload(data).then(
          (response) => {
            this.json_data = response.data;
            alert("CSV GENERATED!");
            return response;
          },
          (error) => {
            this.content =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      },
      generateTaskCsv(data) {
      AdminService.generateTaskDownload(data).then(
        (response) => {
          this.json_data = response.data;
          alert("CSV GENERATED!");
          return response;
        },
        (error) => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    generateQCTaskCsv(data) {
      AdminService.generateQCTaskDownload(data).then(
        (response) => {
          this.json_data = response.data;
          alert("CSV GENERATED!");
          return response;
        },
        (error) => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
      
      },

      filters: {
        moment: function ([date,selected]) {

          if (selected){
            return moment(date).tz("America/Phoenix").format('MMMM Do YYYY, h:mm:ss a')
          } else {
          return moment(date).tz("America/Phoenix").format('MMMM Do YYYY, h:mm:ss a')
        }

/*           if (selected == 'MTC'){
            return moment(date).tz("MTC").format('MMMM Do YYYY, h:mm:ss a')
          } else if (selected == 'IST') {
            moment(date).tz("IST").format('MMMM Do YYYY, h:mm:ss a')
          } else if (selected == 'BST') {
            moment(date).tz("BST").format('MMMM Do YYYY, h:mm:ss a')
          } else {
            moment(date).tz("MTC").format('MMMM Do YYYY, h:mm:ss a')
          }
           */
  }
}

  
  };
  </script>
  
  <style scoped>
  @import "../assets/scss/snow.scss";
  
  .card-container.card {
    padding: 40px 40px;
  }
  
  .card {
    background-color: #f7f7f7;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    display: inline-block !important;
  }
  
  div.flex-wrapper {
    display: flex !important;
  }
  
  .organe {
    border: 1px solid #b8a541 !important;
    background-color: #b8a541 !important;
  }
  
  .custom-link {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.3em;
    box-sizing: border-box;
    background-color: transparent;
    font-size: 0.9em;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
      Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    padding: 0.75em;
    display: block;
    width: 100%;
    font-weight: 400;
    line-height: 1.2em;
    margin: 0;
    border: 1px solid #41b883;
    background-color: #41b883;
    color: #fff;
    min-width: 0;
    width: auto;
    font-weight: 700;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
  }
  
  .margin-left {
    margin-left: 80px !important;
  }

  .table-condensed{
  font-size: 13px;
}
  </style>
  